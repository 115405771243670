<script setup lang="ts">
useHead({
  meta: [{ name: 'robots', content: 'noindex' }]
})
</script>

<template>
  <div>
    <slot />
  </div>
</template>
